<template>
  <div class="statistic-item">
    <component :is="currentVariant.icon" :key="currentVariant.icon" class="statistic-item__icon" />
    <div class="statistic-item__information">
      <div class="statistic-item__information-title">{{ currentVariant.title }}</div>
      <div class="statistic-item__information-quantity">{{ formattedQuantity }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TStatisticItemVariantsData, IStatisticItemProps, IStatisticItemVariantData } from './StatisticItem.types';
import { EStatisticItemVariants } from '~/config/apps/index.types';

const { t } = useI18n();

const props = defineProps<IStatisticItemProps>();
const { variant, quantity } = toRefs(props);

const quantityAnimation = useCountAnimation();

const variants = computed<TStatisticItemVariantsData>(() => ({
  [EStatisticItemVariants.ARCANES]: {
    icon: 'LazySvgoStatisticArcanes',
    title: t('statistic.arcanes'),
  },
  [EStatisticItemVariants.BATTLES]: {
    icon: 'LazySvgoStatisticBattles',
    title: t('statistic.battles'),
  },
  [EStatisticItemVariants.ONLINE]: {
    icon: 'LazySvgoStatisticOnline',
    title: t('statistic.online'),
  },
  [EStatisticItemVariants.OPENED]: {
    icon: 'LazySvgoStatisticOpened',
    title: t('statistic.opened'),
  },
  [EStatisticItemVariants.UPGRADES]: {
    icon: 'LazySvgoStatisticUpgrades',
    title: t('statistic.upgrades'),
  },
  [EStatisticItemVariants.USERS]: {
    icon: 'LazySvgoStatisticUsers',
    title: t('statistic.users'),
  },
}));

const currentVariant = computed<IStatisticItemVariantData>(() => variants.value[variant.value]);
const formattedQuantity = computed(() => GlobalUtils.Currency.spaceInThousand(quantityAnimation.count.value));

watch(
  quantity,
  () => {
    quantityAnimation.start(quantity.value, quantityAnimation.count.value);
  },
  { immediate: true },
);
</script>

<style scoped lang="scss" src="./StatisticItem.scss" />
